import store from "@/store";
import Vue from "vue";
//import { Logger } from "@storefront/core/lib/logger";
import { i18n } from "@storefront/core/i18n";
export default Vue.extend({
  async mounted() {
    await store.dispatch("user/loadUser");
  },
  computed: {
    /**
     *
     * @returns true or false
     */
    isLoggedIn: function() {
      return store.getters["user/getIsLoggedIn"];
    },
  },
  data() {
    return {
      emailReg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    /**
     * subscribe to newsletter
     * @param {string} email
     */
    async subscribe(email) {
      let valid = true;
      if (email == "") {
        const msg = {
          type: "danger",
          title: i18n.t("newsletter_error"),
          text: i18n.t("email_is_required_field"),
        };
        store.dispatch("messages/sendMessage", { message: msg });
        valid = false;
      } else if (!this.emailReg.test(email)) {
        const msg = {
          type: "danger",
          title: i18n.t("newsletter_error"),
          text: i18n.t("email_is_invalid_field"),
        };
        store.dispatch("messages/sendMessage", { message: msg });
        valid = false;
      }
      if (valid == true) {
        const retval = await store.dispatch("user/newsletterSignup", {
          email: email,
        });
        if (retval != false) {
          const msg = {
            type: "success",
            title: i18n.t("newsletter_success"),
            text: i18n.t("newsletter_subscribed"),
          };
          store.dispatch("messages/sendMessage", { message: msg });
        }
      }
    },
  },
});
